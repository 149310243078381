import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { ContractForm } from 'components/Forms/ContractForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { IFormInput } from 'components/Forms/ContractForm/types';
import { useParams } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth';
import { getColumns } from './columns';
import { UserRole } from 'shared/utils/enum/UserEnum';
import ContractsService from 'shared/services/contracts.service';
import { useNotification } from 'shared/hooks/useNotification';
import { CloudUpload, ListAlt } from '@mui/icons-material';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import MachinesService from 'shared/services/machines.service';
import { Progress } from 'components/Progress';
import { colors } from 'shared/constants/theme';
import './index.css';
import { RedesignedTable } from '../RedesignedTable';
import { useDebounce } from 'shared/hooks/useDebounce';

export default function AdminContractsTable(props: any) {
  const { userDeleted, setUserDeleted, allContracts, currency } = props;
  const { customerId } = useParams();
  const {
    user: { role },
  } = useAuth();

  const [search, setSearch] = useState<string>('');
  const [machinesFile, setMachinesFile] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUploadMachineFileModal, setShowUploadMachineFileModal] =
    useState<boolean>(false);
  const [contracts, setContracts] = useState<IFormInput[] | null>(null);
  const [delContract, setDelContract] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [contractData, setContractData] = useState<IFormInput | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useNotification();

  const debouncedSearch = useDebounce(search, 500);

  const handleDeleteContract = useCallback(async () => {
    if (delContract) {
      try {
        await ContractsService.deleteContract(delContract);
        setDelContract('');
        fetchContracts();
        showSnackbar('Successfully Deleted', 'success');
      } catch (err: any) {
        showSnackbar(err.error, 'error');
      }
    }
  }, [delContract]);

  const handleEdit = (contract: IFormInput) => {
    setShowEditModal(true);
    setContractData(contract);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const handleAddMachinesFromFile = async () => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('file', machinesFile);

    try {
      await MachinesService.createMachinesFromFile(formData);
      showSnackbar('Machines created from file successfully', 'success');
    } catch {
    } finally {
      setIsLoading(false);
      setShowUploadMachineFileModal(false);
    }
  };

  const fetchContracts = async () => {
    setContracts(null);
    try {
      const response = allContracts
        ? await ContractsService.getContracts({
            pageNumber: currentPage,
            limit: rowsPerPage,
            currency,
            search: debouncedSearch,
          })
        : await ContractsService.getContractsByUser(customerId!, {
            pageNumber: currentPage,
            limit: rowsPerPage,
            search: debouncedSearch,
            currency,
          });

      const { contracts, totalPages } = response;
      setContracts(contracts);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    if (!userDeleted) return;
    fetchContracts();
    setUserDeleted(false);
  }, [userDeleted]);

  useEffect(() => {
    fetchContracts();
  }, [currency, debouncedSearch, currentPage, rowsPerPage]);

  const columns = useMemo(() => getColumns(role), [role]);

  return (
    <>
      <Grid m="0 0 30px 50px" className="contractSearch"></Grid>
      {role === UserRole.ADMIN && (
        <>
          <Dialog
            open={showUploadMachineFileModal}
            onClose={() => setShowUploadMachineFileModal(false)}
          >
            <DialogTitle>Add Machines</DialogTitle>
            <DialogContent>
              <Button
                color="success"
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
              >
                Upload Machine File
                <VisuallyHiddenInput
                  onChange={(e) => setMachinesFile(e.target.files![0])}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  type="file"
                />
              </Button>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="outlined"
                sx={{
                  color: '#2E7D32',
                }}
                onClick={() => setShowUploadMachineFileModal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddMachinesFromFile}
                color="success"
                variant="contained"
              >
                {!isLoading ? 'Add' : <Progress color="inherit" size={20} />}
              </Button>
            </DialogActions>
          </Dialog>
          <CustomModal open={showModal} onClose={() => setShowModal(false)}>
            <ContractForm
              setShowModal={setShowModal}
              onFinish={fetchContracts}
            />
          </CustomModal>
        </>
      )}
      <RedesignedTable
        addButton={role === UserRole.ADMIN}
        show={role === UserRole.ADMIN}
        tableName="Contracts"
        items={contracts}
        columns={columns}
        search={search}
        setSearch={setSearch}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        onRowDelete={setDelContract}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        searchable={true}
        addButtonText={'Add contract'}
        actions={
          role === UserRole.ADMIN && (
            <>
              <Box
                className="addAdminStyles"
                onClick={() => setShowUploadMachineFileModal(true)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  border: `1px solid ${colors.lightGreen}`,
                  borderRadius: '20px !important',
                  color: colors.black,
                  padding: '5px 15px',
                }}
              >
                <ListAlt
                  sx={{
                    marginRight: '10px',
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                  }}
                />
                <span>Add Machines</span>
              </Box>
            </>
          )
        }
      />
      {role === UserRole.ADMIN && (
        <>
          <CustomModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          >
            <ContractForm
              setShowModal={setShowEditModal}
              editData={{
                ...contractData,
                user: { id: customerId || contractData?.user?.id },
              }}
              onFinish={fetchContracts}
            />
          </CustomModal>
          <CustomModal open={!!delContract} onClose={() => setDelContract('')}>
            <Box className="container">
              <DeleteModal
                title="contract"
                onClick={handleDeleteContract}
                onCancel={() => setDelContract('')}
              />
            </Box>
          </CustomModal>
        </>
      )}
    </>
  );
}
