import { ColumnsType } from 'components/Tables/BaseTable/types';

export const columns: ColumnsType[] = [
  {
    id: 'username',
    label: 'Full Name',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 100,
  },
  {
    id: 'affiliatePercent',
    label: 'Upfront %',
    minWidth: 100,
    render: ({ affiliatePercent }) => `${affiliatePercent}%`,
  },
  {
    id: 'hashratePercent',
    label: 'Hashrate %',
    minWidth: 100,
    render: ({ hashratePercent }) => `${hashratePercent}%`,
  },
];
