import { Link } from 'react-router-dom';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const columns: ColumnsType[] = [
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'affiliatePercent',
    label: 'Affiliate Percent',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'view',
    label: 'View',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: ({ id, role }: any) =>
      role === 'Affiliate' ? (
        <Link
          to={{
            pathname: makeUrl(routesMapping.adminViewAffiliateHome, {
              affiliateId: id,
            }),
          }}
          target="_blank"
        >
          <VisibilityOutlinedIcon
            fontSize="small"
            sx={{
              color: '#323232',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Link>
      ) : (
        '-'
      ),
  },
];
