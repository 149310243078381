import { useCallback, useEffect, useState } from 'react';
import { CustomerForm } from 'components/Forms/UserForms/CustomerForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { columns } from './columns';
import { UserType } from 'shared/types/UserType';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { RedesignedTable } from '../RedesignedTable';
import { useDebounce } from 'shared/hooks/useDebounce';

export const CustomerUsersTable = (props: any) => {
  const { setUserDeleted, setUserUpdated, isCustomer } = props;
  const [search, setSearch] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [userData, setUserData] = useState<any[]>([]);
  const [delUserEmail, setDelUserEmail] = useState<string>('');
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const { showSnackbar } = useNotification();

  const debouncedSearch = useDebounce(search, 500);

  const fetchUsers = async () => {
    setUsers(null);
    try {
      const response = await UsersService.getUsers({
        pageNumber: currentPage,
        limit: rowsPerPage,
        q: debouncedSearch,
        role: UserRole.CUSTOMER,
        ...(isCustomer ? { haveContract: true } : {}),
      });
      const { users, totalPages } = response;
      setTotalPages(totalPages);
      setUsers(users);
      setUserUpdated((prevState: any) => !prevState);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleDeleteUser = useCallback(async () => {
    if (delUserEmail) {
      await UsersService.deleteUser(delUserEmail);
      fetchUsers();
      setDelUserEmail('');
      setUserDeleted && setUserDeleted(true);
      showSnackbar('Successfully Deleted', 'success');
      setUserUpdated((prevState: any) => !prevState);
    }
  }, [delUserEmail]);

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchUsers();
  }, [debouncedSearch, rowsPerPage, currentPage]);

  return (
    <>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <CustomerForm setShowModal={setShowModal} onFinish={fetchUsers} />
      </CustomModal>
      <RedesignedTable
        tableName={isCustomer ? 'Customers' : 'Prospects'}
        items={users}
        columns={columns}
        search={search}
        setSearch={setSearch}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        onRowDelete={setDelUserEmail}
        show={true}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        searchable={true}
        addButton={isCustomer ? false : true}
      />
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <CustomerForm
          setShowModal={setShowEditModal}
          editData={userData}
          onFinish={fetchUsers}
        />
      </CustomModal>
      <CustomModal open={!!delUserEmail} onClose={() => setDelUserEmail('')}>
        <Box className="container">
          <DeleteModal
            title="user"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserEmail('')}
          />
        </Box>
      </CustomModal>
    </>
  );
};
