import { UserRole } from 'shared/utils/enum/UserEnum';

export const routesMapping = {
  initial: '/',
  customer: '/customer',
  supplier: '/supplier',
  affiliate: '/affiliate',
  affiliateOperation: '/affiliate/operation',
  affiliateTransactions: '/affiliate/operation/transactions',
  affiliateTeamMembers: '/affiliate/team-members',
  affiliateUser: '/affiliate/team-members',
  affiliateContract: '/affiliate/management/contract/:id',
  affiliateCustomerDetails: '/affiliate/customer-details/:customerId',
  affiliateViewCustomerHome: '/affiliate/customer-view/:customerId/home',
  affiliateViewCustomerWallet: '/affiliate/customer-view/:customerId/wallet',
  affiliateViewCustomerOperation:
    '/affiliate/customer-view/:customerId/operation',
  affiliateViewCustomerInvoicing:
    '/affiliate/customer-view/:customerId/invoicing',
  affiliateViewCustomerTransaction:
    '/affiliate/customer-view/:customerId/transaction',
  affiliateViewCustomerContract:
    '/affiliate/customer-view/:customerId/operation/contract/:id',
  affiliateSimulation: '/affiliate/simulation',
  affiliateCustomers: '/affiliate/customers',
  profile: '/profile',
  customerHome: '/customer/home',
  customerOperation: '/customer/operation',
  customerTransaction: '/customer/transaction',
  customerInvoicing: '/customer/invoicing',
  customerContract: '/customer/operation/contract/:id',
  customerWallet: '/customer/wallet',
  login: '/login',
  adminOperation: '/admin/operation',
  adminManagement: '/admin/management',
  adminOpportunities: '/admin/opportunities',
  adminSimulation: '/admin/simulation',
  adminViewCustomerHome: '/admin/customer-view/:customerId/home',
  adminViewCustomerOperation: '/admin/customer-view/:customerId/operation',
  adminViewCustomerInvoicing: '/admin/customer-view/:customerId/invoicing',
  adminViewCustomerTransaction: '/admin/customer-view/:customerId/transaction',
  adminViewCustomerWallet: '/admin/customer-view/:customerId/wallet',
  adminViewCustomerContract:
    '/admin/customer-view/:customerId/operation/contract/:id',
  adminViewAffiliateHome: '/admin/affiliate-view/:affiliateId/home',
  adminViewAffiliateOperation: '/admin/affiliate-view/:affiliateId/operation',
  adminViewAffiliateSimulation: '/admin/affiliate-view/:affiliateId/simulation',
  adminViewAffiliateTeamMembers:
    '/admin/affiliate-view/:affiliateId/team-members',
  adminViewAffiliateCustomerView:
    '/admin/affiliate-view/:affiliateId/customer-view/:customerId/home',
  adminViewAffiliateOperationTransactions:
    '/admin/affiliate-view/:affiliateId/operation/transactions',
  signUp: '/sign-up',
  subAccount: '/sub-account',
  adminContract: '/admin/management/contract/:id',
  adminMachine: '/admin/machine/:id',
  adminUser: '/admin/operation/user/:customerId',
  forgotPassword: '/forgot-password',
  verification: '/verification',
};

export const userRedirectionMapping: any = {
  [UserRole.ADMIN]: routesMapping.adminOperation,
  [UserRole.CUSTOMER]: routesMapping.customerHome,
  [UserRole.SUBACCOUNT]: routesMapping.customerHome,
  [UserRole.SUPPLIER]: routesMapping.supplier,
  [UserRole.AFFILIATE]: routesMapping.affiliateCustomers,
  [UserRole.AFFILIATE_SUBACCOUNT]: routesMapping.affiliateCustomers,
};
