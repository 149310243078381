import { Box } from '@mui/material';
import { CustomerForm } from 'components/Forms/UserForms/CustomerForm';
import { SubAccountForm } from 'components/Forms/UserForms/SubAccountForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import usersService from 'shared/services/users.service';
import {
  getColumns,
  getExpandColumns,
} from 'components/Tables/AdminCustomersTable/columns';
import { UserType } from 'shared/types/UserType';
import { useDebounce } from 'shared/hooks/useDebounce';

export const AdminCustomersTable = () => {
  const [delUserEmail, setDelUserEmail] = useState<string>('');
  const [userData, setUserData] = useState<any>();
  const [owner, setOwner] = useState<any>();
  const [limit, setLimit] = useState<number>(5);
  const [search, setSearch] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [showEditSubAccModal, setShowEditSubAccModal] =
    useState<boolean>(false);
  const [showEditCustomerModal, setShowEditCustomerModal] =
    useState<boolean>(false);

  const debouncedSearch = useDebounce(search, 500);

  const { showSnackbar } = useNotification();

  const fetchUsers = async () => {
    setUsers(null);
    try {
      const response = await usersService.getCustomerStatistic({
        pageNumber,
        limit,
        name: debouncedSearch,
      });
      const { users, totalPages } = response;
      setTotalPages(totalPages);
      setUsers(users);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleCountItems = (event: number) => {
    setPageNumber(1);
    setLimit(event);
  };

  const handleSubAccAdd = (owner: any) => {
    setOwner(owner);
    setUserData(null as any);
    setShowEditSubAccModal(true);
  };

  const handleCustomerAdd = () => {
    setUserData(null as any);
    setShowEditCustomerModal(true);
  };

  const handleEditSubAcc = (user: any): void => {
    setShowEditSubAccModal(true);
    setUserData(user);
  };

  const handleEditCustomer = (user: any): void => {
    setShowEditCustomerModal(true);
    setUserData(user);
  };

  const handleDeleteUser = useCallback(async () => {
    if (delUserEmail) {
      await UsersService.deleteUser(delUserEmail);
      fetchUsers();
      setDelUserEmail('');
      showSnackbar('Successfully Deleted', 'success');
    }
  }, [delUserEmail]);

  const columns = getColumns({
    handleEdit: handleEditCustomer,
    onRowDelete: setDelUserEmail,
    onAdd: handleSubAccAdd,
  });
  const expandColumns = getExpandColumns({
    handleEdit: handleEditSubAcc,
    onRowDelete: setDelUserEmail,
    handleAdd: handleSubAccAdd,
  });

  useEffect(() => {
    fetchUsers();
  }, [debouncedSearch, pageNumber, limit]);

  return (
    <>
      <RedesignedTable
        searchable
        tableName="Customers"
        items={users}
        columns={columns}
        search={search}
        setSearch={setSearch}
        currentPage={pageNumber}
        totalPages={totalPages}
        setCurrentPage={setPageNumber}
        rowsPerPage={limit}
        setRowsPerPage={handleCountItems}
        expandColumns={expandColumns}
        expandPropertyName="subAccounts"
        onAdd={() => handleCustomerAdd()}
      />
      <CustomModal
        open={showEditCustomerModal}
        onClose={() => setShowEditCustomerModal(false)}
      >
        <CustomerForm
          setShowModal={setShowEditCustomerModal}
          editData={userData}
          onFinish={fetchUsers}
        />
      </CustomModal>
      <CustomModal
        open={showEditSubAccModal}
        onClose={() => setShowEditSubAccModal(false)}
      >
        <SubAccountForm
          owner={owner}
          setShowModal={setShowEditSubAccModal}
          editData={userData}
          onFinish={fetchUsers}
          users={users}
          disabled={userData}
        />
      </CustomModal>
      <CustomModal open={!!delUserEmail} onClose={() => setDelUserEmail('')}>
        <Box className="container">
          <DeleteModal
            title="user"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserEmail('')}
          />
        </Box>
      </CustomModal>
    </>
  );
};
