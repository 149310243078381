import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { SeeMoreButton } from 'components/Tables/BaseTable/SeeMoreButton';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Link } from 'react-router-dom';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { formatUsd } from 'shared/utils/formatter';

export const getColumns = (isAdminView?: boolean): ColumnsType[] => {
  return [
    {
      id: 'companyName',
      label: 'Company Name',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'totalInvestment',
      label: 'Total investments',
      minWidth: 100,
      align: 'left',
      render: ({ totalInvestment }) => `$${formatUsd(totalInvestment)}`,
    },
    {
      id: 'affiliateUpfront',
      label: 'Upfront payments',
      minWidth: 100,
      align: 'left',
      render: ({ affiliateUpfront }) => `$${formatUsd(affiliateUpfront)}`,
    },
    {
      id: 'contractsNumber',
      label: 'Number of Contracts',
      minWidth: 100,
      align: 'left',
    },
    {
      label: 'Customers details',
      sortable: false,
      minWidth: 150,
      align: 'left',
      render: ({ id, role }) =>
        role === UserRole.CUSTOMER ? (
          <SeeMoreButton
            route={makeUrl(
              isAdminView
                ? routesMapping.adminUser
                : routesMapping.affiliateCustomerDetails,
              {
                customerId: id,
              },
            )}
          />
        ) : (
          <></>
        ),
    },
    {
      id: 'Customer view',
      label: 'Customer view',
      minWidth: 100,
      align: 'left',
      sortable: false,
      render: ({ id, role }: any) =>
        role === 'Customer' ? (
          <Link
            to={{
              pathname: makeUrl(
                isAdminView
                  ? routesMapping.adminViewCustomerHome
                  : routesMapping.affiliateViewCustomerHome,
                {
                  customerId: id,
                },
              ),
            }}
            target="_blank"
          >
            <VisibilityOutlinedIcon
              fontSize="small"
              sx={{
                color: '#323232',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        ) : (
          '-'
        ),
    },
  ];
};
