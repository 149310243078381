import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { SvgComponentSee } from 'components/Icons/See';
import { SvgComponentHide } from 'components/Icons/Hide';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'shared/hooks/useAuth';
import * as yup from 'yup';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import {
  container,
  content,
  formTitle,
  submitButton,
} from 'components/Forms/styles';

export const SubAccountForm = ({
  setShowModal,
  editData,
  onFinish,
  users,
  disabled,
  owner,
}: any): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const { showSnackbar } = useNotification();

  const { user } = useAuth();

  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').email().trim(),
    username: yup.string().required('Username is a required field'),
    password: editData?.id
      ? yup.string()
      : yup
          .string()
          .required('Password is a required field')
          .min(8, 'Password must be at least 8 characters'),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!users || !users.length || !editData) return;

    setSelectedUser(users.find((u: any) => u.id === editData?.owner?.id));
  }, [users, editData]);

  const ownerData = useMemo(() => {
    if (selectedUser)
      return { id: selectedUser.id, companyName: selectedUser.companyName };
    if (owner) return { id: owner.id, companyName: owner.companyName };

    return { id: user?.id, companyName: user?.companyName };
  }, [owner, selectedUser, user]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit: any = async (userData: any): Promise<void> => {
    try {
      const payload = {
        ...userData,
        ownerId: ownerData?.id,
        companyName: ownerData?.companyName,
        role: 'SubAccount',
      };

      if (editData) {
        await UsersService.updateUser(editData.id, {
          ...userData,
          ownerId: ownerData?.id,
        });
        showSnackbar('Successfully Edited', 'success');
      } else {
        await UsersService.createUser(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Add'} Subaccount
        </Typography>
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.username ?? ''}
          name="username"
          label="Username"
          error={errors}
          placeholder="Username"
          disableAutocomplete
        />
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.email ?? ''}
          name="email"
          label="Email"
          error={errors}
          placeholder="name.example@gmail.com"
        />
        {users && users.length ? (
          <Box width="100%">
            <Box display="flex" columnGap={0.5} marginY={0.5}>
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Owner
              </Typography>
              <Typography fontSize="15px" color="#ff4242">
                *
              </Typography>
            </Box>
            <Select
              defaultValue={editData?.owner?.id || owner?.id}
              disabled={disabled || owner}
              fullWidth
              sx={{
                marginTop: '8px',
                marginBottom: '4px',
              }}
              value={selectedUser?.id}
              onChange={(e: any) =>
                setSelectedUser(users.find((u: any) => u.id === e.target.value))
              }
            >
              {users.map((option: any, index: number) => (
                <MenuItem value={option.id} key={`select-${index}`}>
                  {option.email}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <></>
        )}
        {users && users.length ? (
          <Box width="100%">
            <Box display="flex" columnGap={0.5} marginY={0.5}>
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Company
              </Typography>
              <Typography fontSize="15px" color="#ff4242">
                *
              </Typography>
            </Box>
            <TextField
              defaultValue={editData?.companyName || owner?.companyName}
              placeholder="Company"
              variant="outlined"
              fullWidth
              margin="dense"
              disabled
              value={selectedUser?.companyName}
            />
          </Box>
        ) : (
          <></>
        )}
        {!editData?.id && (
          <ControlledTextField
            control={control}
            defaultValue=""
            name="password"
            label="Password"
            placeholder="Password"
            error={errors}
            required
            disableAutocomplete
            type={showPassword ? 'text' : 'password'}
            textFieldProps={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <SvgComponentSee />
                      ) : (
                        <SvgComponentHide />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        <Box className="buttons">
          <Button sx={submitButton} type="submit">
            {editData ? 'Save Changes' : 'Add User'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
