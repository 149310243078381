import { useEffect, useState } from 'react';
import { columns } from './columns';
import ContractsService from 'shared/services/contracts.service';
import { useNotification } from 'shared/hooks/useNotification';
import { RedesignedTable } from '../RedesignedTable';
import { SectionHeading } from 'components/SectionHeading';

export const HosterContractsTable = () => {
  const [contracts, setContracts] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const { showSnackbar } = useNotification();

  const fetchContracts = async () => {
    try {
      const response = await ContractsService.getHosterContracts({
        pageNumber: currentPage,
        limit: rowsPerPage,
      });

      const { contracts, totalPages } = response;

      setContracts(contracts);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchContracts();
  }, [rowsPerPage, currentPage]);

  return (
    <>
      <SectionHeading
        sx={{
          color: '#000',
          marginBottom: '20px',
          marginTop: '40px',
        }}
        name="Contracts"
      />
      <RedesignedTable
        tableName=""
        items={contracts}
        columns={columns}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        searchable={false}
      />
    </>
  );
};
