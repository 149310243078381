import { Currency, HardwareCurrency } from './enum/coinEnums';
import alephiumLogo from 'assets/images/coinLogos/alephium-black-logo.svg';
import bitcoinLogo from 'assets/images/coinLogos/bitcoin-logo.svg';
import bitcoinCashLogo from 'assets/images/coinLogos/bitcoincash-logo.svg';
import kaspaLogo from 'assets/images/coinLogos/kaspa-logo.svg';
import litecoinLogo from 'assets/images/coinLogos/litecoin-logo.svg';
import nervosLogo from 'assets/images/coinLogos/nervos-black-logo.svg';

export const handleCurrencyName = (value: any) => {
  if (value === Currency.Litecoin) return 'Litecoin + Dogecoin';
  if (value === Currency.BitcoinCash) return 'Bitcoin Cash';

  return value;
};

export const handleCoinAbb = (coin: any) => {
  switch (coin) {
    case 'Bitcoin':
      return 'BTC';
    case 'BitcoinCash':
    case 'Bitcoin Cash':
      return 'BCH';
    case 'Litecoin':
      return 'LTC';
    case 'Kaspa':
      return 'KAS';
    case 'Nervos':
      return 'CKB';
    case 'Alephium':
      return 'ALPH';
    default:
      return 'BTC';
  }
};

export const coins = [
  {
    coinName: 'Bitcoin',
    currency: Currency.Bitcoin,
    coinAbb: 'BTC',
    logo: bitcoinLogo,
  },
  {
    coinName: 'Bitcoin Cash',
    currency: Currency.BitcoinCash,
    coinAbb: 'BCH',
    logo: bitcoinCashLogo,
  },
  {
    coinName: 'Litecoin',
    currency: Currency.Litecoin,
    coinAbb: 'LTC',
    logo: litecoinLogo,
  },
  {
    coinName: 'Kaspa',
    currency: Currency.Kaspa,
    coinAbb: 'KAS',
    logo: kaspaLogo,
  },
  {
    coinName: 'Nervos',
    currency: Currency.Nervos,
    coinAbb: 'CKB',
    logo: nervosLogo,
  },
  {
    coinName: 'Alephium',
    currency: Currency.Alephium,
    coinAbb: 'ALPH',
    logo: alephiumLogo,
  },
];

export const currencies = Object.values(HardwareCurrency).map((value) => ({
  value,
  name: value,
}));

export const handleDifficultyChartPoint = (coin: any) => {
  switch (coin) {
    case Currency.Kaspa:
    case Currency.Nervos:
      return 'E';
    case Currency.Alephium:
      return 'P';
    case Currency.Litecoin:
      return 'M';
    case 'Bitcoin Cash':
      return 'G';
    default:
      return 'T';
  }
};
