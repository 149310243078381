import { Box, TableCell } from '@mui/material';
import editIcon from 'assets/images/editIcon.svg';
import removeIcon from 'assets/images/removeIcon.svg';

const Action = (props: any) => {
  const { readOnlyWithActions, onClick, icon } = props;

  return (
    <Box
      onClick={() => !readOnlyWithActions && onClick()}
      component="img"
      sx={{
        cursor: 'pointer',
        marginRight: '10px',
        height: 50,
        width: 50,
        maxHeight: { xs: 25, md: 25 },
        maxWidth: { xs: 25, md: 25 },
        opacity: readOnlyWithActions ? 0.5 : 1,
      }}
      src={icon}
    />
  );
};

export const TableActions = ({
  handleEdit,
  row,
  rowData,
  onRowDelete,
  readOnlyWithActions,
}: any) => {
  return (
    <TableCell align="center" sx={{ minWidth: '130px', flexWrap: 'nowrap' }}>
      {handleEdit && (
        <Action
          readOnlyWithActions={readOnlyWithActions}
          onClick={() => handleEdit(row, rowData)}
          icon={editIcon}
        />
      )}
      {onRowDelete && (
        <Action
          readOnlyWithActions={readOnlyWithActions}
          onClick={() => onRowDelete(row.id)}
          icon={removeIcon}
        />
      )}
    </TableCell>
  );
};
